import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const Page = loadable(() => import('~layout/Page'));
const SEO = loadable(() => import('~layout/SearchEngineOptimization'));
const DonateAbout = loadable(() => import('~components/donate'), {
  resolveComponent: components => components.DonateAbout
});
const DonateContact = loadable(() => import('~components/donate'), {
  resolveComponent: components => components.DonateContact
});
const DonateForm = loadable(() => import('~components/donate'), {
  resolveComponent: components => components.DonateForm
});

const DonateHero = loadable(() => import('~components/donate'), {
  resolveComponent: components => components.DonateHero
});

const SponsorAday = loadable(() => import('~components/donate'), {
  resolveComponent: components => components.SponsorAday
});

const seo = {
  seo_title: 'Support Aleph Beta’s Mission | Jewish Non-Profit Organization',
  seo_description:
    'Aleph Beta seeks to renew the romance of the Jewish People with its founding document. Join our mission to help students fall in love with Torah every day.',
  seo_keywords: 'jewish charity, jewish non profit organization, donate to jewish charities'
};

const DONATE_SECTION_DATA = [
  {
    title: 'Our Mission',
    text: "Aleph Beta's mission is to create relevant Jewish learning: to take people on a deep journey through the text of the Torah and emerge with meaningful and satisfying answers to some of life’s biggest questions. We believe Torah study should be evidence-based, intellectually stimulating, and emotionally gripping."
  },
  {
    title: "We're Nonprofit",
    text: 'Aleph Beta is a nonprofit organization, and we couldn’t do what we do without the support of people like you. Over 10,000 passionate supporters made a contribution to Aleph Beta in the last year — and every single one has made a difference. All subscriptions and donations to Aleph Beta are 100% tax-deductible.'
  },
  {
    title: 'We’re Making An Impact',
    text: 'Over 70,000 users regularly visit Aleph Beta’s website and mobile app to access our innovative Torah content: sleekly-produced animated videos, audios, and guides. Thanks to our special program for educators, students in over 200 schools have the opportunity to engage with Aleph Beta content in their classrooms. We hear everyday from users who tell us that we are revolutionizing their relationship with Torah learning and giving them precious tools for spiritual growth.'
  }
];
const EXAMPLE_DONATION_AMOUNTS = [18, 50, 100, 180, 360, 1800, 5000, 10000];

const Donate = () => (
  <Page redirectToMembers>
    <SEO {...seo} />

    <DonateHero />

    <SponsorAday />

    <DonateForm donationAmounts={EXAMPLE_DONATION_AMOUNTS} />

    <DonateAbout sections={DONATE_SECTION_DATA} />

    <DonateContact />
  </Page>
);

Donate.propTypes = {
  location: PropTypes.shape().isRequired
};

export default Donate;
